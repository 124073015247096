import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Helmet } from "react-helmet";
import styled, { css } from 'styled-components'
import Transition from '../../transition';
import 'swiper/css/swiper.css';

import { MetaTags, Header, Footer, CallToAction } from '../'
import { media } from '../../styles/utils';
import { useLocation } from 'react-use';

const Layout = ({ children, meta, ...props }) => {
	const location = useLocation();

	return (
		<>
			<MetaTags
				{...meta}
			/>

			<Helmet
				script={[{ 
					type: 'text/javascript', 
					innerHTML: 'window.yourObject = { it: "works" }' 
				}]} 
			>
				<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"/>

				<script type="text/javascript">
					{`
					  (function(w, d, t, h, s, n) {
						w.FlodeskObject = n;
						var fn = function() {
						  (w[n].q = w[n].q || []).push(arguments);
						};
						w[n] = w[n] || fn;
						var f = d.getElementsByTagName(t)[0];
						var v = '?v=' + Math.floor(new Date().getTime() / (120 * 1000)) * 60;
						var sm = d.createElement(t);
						sm.async = true;
						sm.type = 'module';
						sm.src = h + s + '.mjs' + v;
						f.parentNode.insertBefore(sm, f);
						var sn = d.createElement(t);
						sn.async = true;
						sn.noModule = true;
						sn.src = h + s + '.js' + v;
						f.parentNode.insertBefore(sn, f);
					  })(window, document, 'script', 'https://assets.flodesk.com', '/universal', 'fd');
					`}
				</script>
			</Helmet> 
			
			<Header
				overSlider={props.headerOverSlider}
				theme={props.headerTheme}
			/>

			<Wrapper
				isHome={location.pathname == '/'}
			>
				<Transition>
					{children}
				</Transition>
			</Wrapper>

			{props.callToAction && (
				<CallToAction/>
			)}

			<Footer/>
		</>
	)
}

const Wrapper = styled.div`
	width: 100%;
	min-height: calc(100vh - 235px - 342px);
	max-width: 100vw;
	box-sizing: border-box;

	> * {
		width: 100%;
	}

	${media.phone`
		min-height: 0;
	`}
`

export default Layout
