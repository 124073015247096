import React, { Component } from 'react'
import { Checkbox } from '../components'
import { required, email, phone, number, password, requiredBool } from '../utils/validators'

export const contactForm = {
    fields: [
        {
            type: 'text',
            name: 'name',
            label: 'Name *',
            placeholder: 'Name *',
            validator: required,
            errorMessage: 'Please enter a valid name',
        },
        {
            type: 'email',
            name: 'email',
            label: 'Email *',
            validator: email,
            errorMessage: 'Please enter a valid email',
        },
        {
            type: 'tel',
            name: 'mobile',
            label: 'Mobile *',
            validator: required,
            errorMessage: 'Please enter a valid mobile number',
        },
        {
            type: 'text',
            name: 'suburb',
            label: 'Suburb',
            validator: () => { return true },
        },
        {
            type: 'textarea',
            name: 'enquiry',
            label: 'Enquiry',
            validator: () => { return true },
        },
    ]
}

