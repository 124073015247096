import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import ProgressiveImage from "react-progressive-image"
import Swiper from 'react-id-swiper'

import { media } from '../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage, maxWidth } from '../../styles/global'
import { useMount } from 'react-use';
import { Link } from 'gatsby';
import { lightGrey } from '../../styles/colors';

// Import SVG file directly
import journalViewAll from '../../assets/icons/journal-view-all.svg'

const JournalFeed = (props) => {
	const {slides, sliderStyles} = props;
	const [swiper, updateSwiper] = useState(null);
	const canLoop = slides && slides.length > 1;

	const swiperParams = {
		getSwiper: updateSwiper,
		// loop: canLoop,
		slidesPerView: 'auto',
		spaceBetween: 25,
		freeMode: true,
	}

	const renderViewAll = () => {
		return (
			<ViewAll>
				<JournalLink
					to={'/journal'}
				>
					<Arrow/>
					<Heading>
						View All
					</Heading>
				</JournalLink>
			</ViewAll>
		)
	}
	
	
	const renderSlides = () => {
		if (!slides) return;
		
		const items = slides.map((item, i) => {
			return (
				<Slide
					key={i}
				>	

					{item.hero_image && (
						<Image 
							className={'slider-image'}
							image={item.hero_image.sizes.medium2} 
							style={{
								backgroundImage: `url(${item.hero_image.sizes.medium2})`,
							}}
						/>
					)}

					{item.cats && item.cats.length && (
						<Subheading>{item.cats[0].name}</Subheading>
					)}

					<Heading>{item.title}</Heading>
					
				</Slide>
			)
		})

		// Inject 'View All' slide

		items.push(renderViewAll())

		return items
	}

	return (
		<Wrapper
			sliderStyles={sliderStyles}
		>
			<Swiper
				{...swiperParams}
			>
				{renderSlides()}
			</Swiper>
		</Wrapper>
	)
}

// Shared

const Heading = styled.h2``
const Subheading = styled.h3``

const Image = styled.div`
	background-image: url(${props => props.image});  
	${bgImage}
`

// Layout

const defaultSliderStyles = css`
	max-width: 100vw;

	${media.tabletPortrait`
		padding-left: 84px;
	`}

	${media.phone`
		padding-left: 44px;
	`}
`

const Wrapper = styled.div`
	position: relative;

	.swiper-container {
		${props => props.sliderStyles || defaultSliderStyles};
		cursor: grab;
	}
`

// Slide

const Slide = styled.div`
	width: 305px !important;
	color: ${lightGrey};

	${Image} {
		height: 202px;
		margin-bottom: 28px;
	}

	${Subheading} {
		font-size: 14px;
	}

	${Heading} {
		margin-top: 8px;
	}
`

// View All

const Arrow = styled.div``
const JournalLink = styled(Link)``

const ViewAll = styled(Link)`
	width: 200px !important;
	height: 202px !important;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-right: 30%;
	box-sizing: border-box;

	${JournalLink} {
		display: flex;
		flex-direction: column;
		align-items: center;
		
		${Arrow} {
			${bgImage}
			background-image: url(${journalViewAll});
			height: 102px;
			width: 102px;
			box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
			border-radius: 50%;
		}

		${Heading} {
			font-size: 18px;
			margin-top: 24px;
			color: ${lightGrey};
		}
	}

	&:focus,
	&:active {
		outline: none;
	}
`


export default JournalFeed
