import React, { useState, useRef } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css, keyframes } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import Form from 'react-dynamic-form'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import loadingIcon from '../assets/icons/loading.svg'

import { Layout, Input} from '../components'

import { container, padding, headingText, wrapper, bgImage, bgIcon, button } from '../styles/global'
import { parseACF } from '../utils';
import { useSetState } from 'react-use';
import { media, useBreakpoint } from '../styles/utils';
import { postData } from '../services/api';
import { contactForm } from '../data/contact';

const Contact = (props) => {

    let data = parseACF(useStaticQuery(query), 'allWordpressInfopages', true);
    
    if (props.previewData) {
        data = JSON.parse(props.previewData.acf_json) 
    }

    const form = useRef(null);   
    const isPhone = useBreakpoint('phone');

    const [formState, updateForm] = useSetState({
		submitting: false, 
		formComplete: false,
		error: false,
    })

    const handleSubmit = (fields, resetForm) => {


        const data = {
			email: fields['email'].value,
			name: fields['name'].value,
			mobile: fields['mobile'].value,
            suburb: fields['suburb'].value,
            enquiry: fields['enquiry'].value,      
        }

        updateForm({submitting: true})



		postData('/pda/contact', data)
			.then(res => {
				if (res.success) {
					updateForm({
						error: null,
                        submitting: false,
                        formComplete: true
					})
				} else {
					updateForm({
						error: res.message,
						submitting: false
					})
				}
				
				resetForm()
			})
			.catch(error =>  {
				updateForm({
					error: `Server Error`,
					submitting: false
				})
				resetForm()
			})  
    }

    const renderIntroduction = () => {
        if (!data.display_introduction) return

        return (
            <HeadingText>
                <Heading>{data.introduction_heading}</Heading>

                <Info>
                    <Description
                        dangerouslySetInnerHTML={{__html: data.introduction_text}}  
                    />
                </Info>
            </HeadingText>
        )
    }
    
    const renderContactInfo = () => {
        return (
            <ContactInfo
                dangerouslySetInnerHTML={{__html: data.contact_details}}  
            />
        )
    }
    
    
	const renderForm = () => {
        const { error, submitting, formComplete } = formState;
		
		return (
			<FormWrapper
                formComplete={formComplete}
            >
				<Form
					ref={form}
					data={contactForm}
					renderInput={<Input/>}
					styles={formStyles}
					renderSubmit={false}
                    onSubmit={handleSubmit}
                    moveToInvalidField={isPhone}
				/>

				{error && (
					<Error>{error}</Error>
				)}

				<Button
                    submitting={submitting}
					onClick={() => {
						form.current && form.current.handleSubmit()
					}}
				>
					{submitting ? <Loading/> : 'Submit'}
				</Button>
			</FormWrapper>
		)
    }
    
    const renderThankYou = () => {
        if (!formState.formComplete) return;

        return (
            <ThankYou>
                Thanks! We'll be in touch with you about your project as soon as possible 
            </ThankYou>
        )
    }
    
	return (
		<Layout
            meta={data && data.seo}
            callToAction={true}
        >
			<Wrapper>
                <Container>
                    {renderIntroduction()}
                    {renderContactInfo()}
                    {renderForm()}
                    {renderThankYou()}
                </Container>
            </Wrapper>
		</Layout>	
	)
}

// Shared

const Heading = styled.h2``
const Subheading = styled.h3``
const Description = styled.div``
const Info = styled.div``

const Button = styled.div`
    ${button}
    cursor: pointer;

    ${props => {
        if (props.submitting) return css`
            background: #C4C4C4;
        `
    }}
`

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${props => props.image});  
    ${bgImage};
    transition: opacity 0.45s ease;
`


// Layout

const Wrapper = styled.div`
    ${wrapper}
    padding-bottom: 130px;

    ${media.phone`
        padding-bottom: 100px;
    `}
`

const Container = styled.div`
    ${container}
    ${padding}
    flex-direction: column;
`

// Heading Text

const HeadingText = styled.div`
    ${headingText}
    margin-bottom: 32px;

    ${Info} {
        max-width: calc(50% - 25px);
    }

    ${media.phone`
        ${Info} {
            max-width: none;
        }
    `}
`

// Contact Info

const ContactInfo = styled.div`
    margin-bottom: 90px;

    &, p, a {
        font-size: 18px;
        color: #666666;
    }

    ${media.phone`
        margin-bottom: 70px;
    `}
`

// Form

const FormWrapper = styled.div`
	display: flex;	
	flex-direction: column;	
    align-items: flex-start;
    width: 100%;
    transition: opacity 0.25s ease;

	${Button} {
		margin-top: 30px;
        min-width: 157px;

        ${media.phone`
            min-width: none;
            width: 100%;
        `}
	}

    ${props => {
        if (props.formComplete) return css`
            opacity: 0.4;
            pointer-events: none;
        `
    }}
`

const formStyles = css`
	flex-direction: column;
	align-items: flex-start;
    width: 100%;

    display: flex;	
	position: relative;
	
	/* Field overrides */

	.dynamic-fields {
        width: 100%;
        display: flex;
        justify-content: space-between;
        
		&:not(:first-child) {
			margin-top: 15px;
		}
	}

	.dynamic-field {
		margin-bottom: 0;

        &:not(.enquiry) {
            flex: 0 1 calc(50% - 24px);
            flex-basis: calc(50% - 24px);

            ${media.phone`
                flex: 1 0 100%;
            `}
        }   

        &.enquiry {
            margin-top: 24px;

            .field {
                display: flex;
                flex-direction: column;
            }
        }
	}
`

const ResetPassword = styled(Link)`
    margin-top: 30px;
`

const Error = styled.div`
	font-size: 16px;
	margin-top: 12px;
	color: red;
`

const ThankYou = styled.div`
	margin-top: 27px;
    font-size: 18px;
`

const rotate360 = keyframes`
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
`

const Loading = styled.div`
	width: 20px;
	height: 20px;
    background-image: url(${loadingIcon});
	${bgIcon}
	animation: ${rotate360} 1s linear infinite;
`


export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "contact" } }) {
            nodes {
                acf_json
            }
        }
    }
`


export default Contact

