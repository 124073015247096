import React, { Component } from 'react'
import { navigate, Link, graphql, useStaticQuery } from 'gatsby'
import styled, { css } from 'styled-components'
import { useMount, useUnmount } from 'react-use';

import { media } from '../../styles/utils'
import { container, bgIcon, padding, hoverState, linkText } from '../../styles/global'

// Import SVG file directly
import categoryArrow from '../../assets/icons/category-arrow.svg'

const CategoryFilter = ({className, items, hideAll, ...props}) => {
    const categories = items || useStaticQuery(query).allWordpressNewscats.nodes;

    const onCategoryChange = (slug) => {
        props.onChange && props.onChange(slug)
    }

    const renderCategories = () => {
        const items = categories && categories.map((item, i) => {
            return (
                <Category
                    key={i}
                    active={item.slug == props.activeCategory}
                    onClick={() => onCategoryChange(item.slug)}
                >
                    <Arrow/>
                    {item.title}
                </Category>
            )
        })

        return (
            <Categories>
                {!hideAll && (
                    <Category
                        active={!props.activeCategory}
                        onClick={() => onCategoryChange(null)}
                    >
                        <Arrow/>
                        All
                    </Category>
                )}

                {items}
            </Categories>
        )
    }

    return (
        <Wrapper
            className={className}
        >
            {renderCategories()}
        </Wrapper>
    )
}

const Wrapper = styled.div``

const Categories = styled.div`
    display: flex;
    flex-direction: column;
`

const Arrow = styled.div`
    background-image: url(${categoryArrow});
    height: 13px;
    ${bgIcon};
    transition: all 0.25s ease;
    will-change: width;
    opacity: 0;
    transform: translateY(1px);
    background-position: left center;
`


const Category = styled.div`
    ${linkText}
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: all 0.25s ease;
    user-select: none;

    ${props => {
        if (props.active) return css`
            color: black;

            ${Arrow} {
                width: 24px;
                opacity: 1;
            }
        `
    }}
`

const query= graphql`
    query NewsCats {
        allWordpressNewscats {
            nodes {
                slug
                title
            }
        }
    }
`

export default CategoryFilter