import React, {Component} from 'react'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'

import { media } from '../../styles/utils'
import { bgIcon } from '../../styles/global'
import { lightGrey, border } from '../../styles/colors';

class Input extends Component {

    state = {
        focused: false
    }

    onBlur = () => {
        const { toggleFocus } = this.props;

        this.setState({ focused: false })

        if (toggleFocus) {
            toggleFocus(false)
        }
    }

    onFocus = () => {
        const { toggleFocus } = this.props;

        this.setState({ focused: true })

        if (toggleFocus) {
            toggleFocus(true)
        }
    }

    shouldComponentUpdate() {
        // Stop stripe fields from updating

        if (this.props.stripeOptions) {
            return false
        }

        return true
    }
    
    onChangeValue = (e) => {
        const { validator, onChangeValue } = this.props;
        const value = e.target.value;
        
        if (onChangeValue) {
            onChangeValue(value, validator)
        }
    }

    shouldShowError = () => {
        const { focused } = this.state;
        const { shouldValidate, isValid } = this.props;

        if (shouldValidate === true && !isValid) {
            return true
        }

        if (shouldValidate && !isValid && !focused) {
            return true
        }
    }

    getInputProps = () => {
        const { value, label, type, placeholder, className, disabled, name, locale, id, icon, inputProps} = this.props;
        const showError = this.shouldShowError();

        return {
            className: className,
            id: name,
            type: type,
            placeholder: placeholder || label,
            value: value,
            disabled: disabled,
            hasIcon: icon,
            onChange: !disabled && this.onChangeValue,
            error: showError,
            onFocus: this.onFocus,
            onBlur: this.onBlur,
            ...inputProps
        }
    }
    
    render() {
        const { focused } = this.state;
        const { errorMessage, helperText, id, icon, type, label, validator, component, placeholder } = this.props;
        const showError = this.shouldShowError();
        const CustomComponent = component || false;

        return (
            <Wrapper
                id={id}
                className={[id, 'dynamic-field']}
            >
                <FieldWrapper
                     className={'field-wrapper'}
                >
                    <Field
                        className={'field'}
                        error={showError}
                    >
                        {!CustomComponent && (
                            <>
                                {type == 'textarea' ? 
                                    (
                                        <>
                                            <Heading>{label}</Heading>
                                            <TextField 
                                                {...this.getInputProps()}
                                                placeholder={'Please be as brief or as descriptive as you wish'}
                                            />
                                        </>
                                    )
                                :
                                    <InputField 
                                        {...this.getInputProps()}
                                    />
                                }   
                            </>
                        )}

                        {CustomComponent && (
                            <CustomComponent
                                {...this.props}
                                {...this.props.stripeOptions}
                            />
                        )}
                        
                    </Field>

                    {helperText && (
                        <HelperText>
                            {helperText}
                        </HelperText>
                    )}

                </FieldWrapper>

                {showError && (
                    <Error
                        className={'error'}
                    >
                        {errorMessage}
                    </Error>
                )}

            </Wrapper>
        )
    }
}

export const red = '#BE7474';

const Heading = styled.div`
    color: ${lightGrey};
`

export const Wrapper = styled.div`
    position: relative;
    width: 100%;
`

const FieldWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`

// Field

export const inputStyle = css`
    appearance: none;
    box-shadow: none;
    display: flex;
    flex: 1;
    height: 80px;
    width: 100%;
    box-sizing: border-box;
    border: 0;
    box-sizing: border-box;
    background: transparent;
    padding-right: 15px;
    border: 0;
    border-bottom: 1px solid ${border};
    transition: padding 0.15s ease;
    border-radius: 0;
    padding-left: 0;

    &, &::placeholder {
        font-size: 24px;
        font-family: 'Neue Haas', Arial, Helvetica, sans-serif;
        color: black;

        ${media.phone`
            font-size: 22px;
        `}
    }

    &::placeholder {
        color: ${lightGrey};
    }
    
    &:focus {
        outline: none;
    }

    ${props => {
        if (props.disabled) return css`
            pointer-events: none;
            
            &, &::placeholder {
                color: rgba(0, 0, 0, 0.2);
            }
        `
    }}

    ${props => {
        if (props.hasIcon) return css`
            padding-left: 43px;
            padding-bottom: 2px;
        `
    }}

    ${props => {
        if (props.error) return css`
            border-color: ${red}; 
            padding-bottom: 10px;
        `
    }}
`

const Field = styled.div`
    display: flex;
    width: 100%;
    position: relative;

    select {
        ${inputStyle}
        border-radius: 0;
    }
`

export const InputField = styled.input`
    ${inputStyle}
`

export const TextField = styled.textarea`
    ${inputStyle}
    min-height: 140px;
    resize: vertical;
    padding-top: 8px;
    box-sizing: border-box;

    &::placeholder {
        opacity: 0.5;
    }
`

// Label

const Info = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 20px;


    ${media.phone`
        display: none;
    `}
    
`

const Label = styled.div`
    font-size: 16px;
    padding-top: 4px;
    color: white;

    ${props => {
        if (props.error) return css`
            color: ${red}
        `
    }}
`


const message = css`
    font-size: 12px;
`

// Helper Text

export const HelperText = styled.div`
    ${message}
    position: absolute;
    right: -24px;
    max-width: 240px;
    transform: translateX(100%);

    ${media.tabletLandscape`
		display: none;
	`}
`

// Error

export const error = css`
    ${message}
    color: ${red} !important; 
    margin-top: 8px;
	margin-left: auto;

    position: absolute;
    left: 0px;
    bottom: 8px;
`

export const Error = styled.div`
    ${error}
`

export default Input;